import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import CardSignIn from 'pages/pages/authentication/card/SignIn';
import CardSignUp from 'pages/pages/authentication/card/SignUp';
import CardForgotPassword from 'pages/pages/authentication/card/ForgotPassword';
import CardSignOut from 'pages/pages/authentication/card/SignOut';
import Error404 from 'pages/error/Error404';
import CardResetPassword from 'pages/pages/authentication/card/ResetPassword';
import CardTwoFA from 'pages/pages/authentication/card/TwoFA';
import CardLockScreen from 'pages/pages/authentication/card/LockScreen';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Ecommerce from 'pages/dashboard/ecommerce';
import ProjectManagement from 'pages/dashboard/ProjectManagement';
import Crm from 'pages/dashboard/Crm';
import App from 'App';
import BasicTableExample from 'pages/modules/tables/BasicTableExample';
import DataSources from 'pages/modules/tables/DataSources';
import AddDataSources from 'pages/modules/tables/AddDataSources';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Ecommerce />
          },
          {
            path: '/dashboard',
            children: [
              {
                path: 'project-management',
                element: <ProjectManagement />
              },
              {
                path: 'crm',
                element: <Crm />
              }
            ]
          },
          {
            path: '/panel',
            children: [
              {
                path: 'basic-tables',
                element: <BasicTableExample />
              },
              {
                path: 'data-sources',
                element: <DataSources />
              },
              {
                path: 'add-data-sources',
                element: <AddDataSources />
              }
            ]
          }
        ]
      },
      {
        path: '/pages/authentication/card/',
        children: [
          {
            path: 'sign-in',
            element: <CardSignIn />
          },
          {
            path: 'sign-up',
            element: <CardSignUp />
          },
          {
            path: 'sign-out',
            element: <CardSignOut />
          },
          {
            path: 'forgot-password',
            element: <CardForgotPassword />
          },
          {
            path: 'reset-password',
            element: <CardResetPassword />
          },
          {
            path: 'lock-screen',
            element: <CardLockScreen />
          },
          {
            path: '2FA',
            element: <CardTwoFA />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
