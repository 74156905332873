import Button from 'components/base/Button';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import { ColumnDef } from '@tanstack/react-table';
import React, { useState } from 'react';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const DataSources = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  type Data = {
    name: string;
    email: string;
    access: string;
  };

  const columns: ColumnDef<Data>[] = [
    {
      header: 'Data Source',
      accessorKey: 'name'
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: ({ row: { original } }) => (
        <Link to={`mailto:${original.email}`}>{original.email}</Link>
      )
    },
    {
      header: 'Access',
      accessorKey: 'access'
    },
    {
      id: 'sync',
      cell: () => (
        <>
          <Button variant="info" className="me-2 mb-1">
            Sync
          </Button>
        </>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    },
    {
      id: 'action',
      cell: cell => (
        <>
          <Button
            onClick={() =>
              removeClick(cell.row.original.name, cell.row.original.email)
            }
            variant="info"
            className="me-2 mb-1"
          >
            Remove
          </Button>
        </>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const removeClick = (type: string, email: string) => {
    axios
      .get(
        `https://api.infora.agency/api/v1/datasource/delete/${type}/${email}`
      )
      .then(res => {
        setData(res.data);
      });
  };

  const fetchTableData = () => {
    axios.get('https://api.infora.agency/api/v1/datasources').then(res => {
      setData(res.data);
    });
  };
  useEffect(() => {
    fetchTableData();
  }, []);
  const table = useAdvanceTable({
    data: data,
    columns: columns,
    pageSize: 6,
    pagination: true,
    selection: true,
    sortable: true
  });

  const handleClick = () => {
    navigate('/panel/add-data-sources');
  };

  return (
    <PhoenixDocCard className="mb-4">
      <PhoenixDocCard.Body>
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Data Sources</h2>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <Button variant="primary" onClick={handleClick}>
              Add Data Sources
            </Button>
          </div>
        </div>
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              size: 'sm',
              className: 'phoenix-table fs-9 mb-0 border-top border-200'
            }}
            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
          />
          <AdvanceTableFooter pagination />
        </AdvanceTableProvider>
      </PhoenixDocCard.Body>
    </PhoenixDocCard>
  );
};
export default DataSources;
