import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
import { setUserSession } from 'common';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const history = useNavigate();
  const email = useFormInput('');
  const password = useFormInput('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleLogin = () => {
    if (validateEmail(email.value)) {
      setLoading(true);
      axios
        .post('https://api.infora.agency/api/login', {
          email: email.value,
          password: password.value
        })
        .then(response => {
          setLoading(false);
          if (response.data.logedIn) {
            setUserSession(response.data.token, response.data.user);
            history('/');
          } else {
            setLoginError('Invalid username or password');
          }
        })
        .catch(error => {
          setLoading(false);
          setLoginError(error.message);
          //console.log(error);
        });
    } else {
      setEmailError('Invalid email format');
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Email address</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            {...email}
            type="email"
            isInvalid={!!emailError}
            className="form-icon-input"
            placeholder="name@example.com"
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Password</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            {...password}
            type="password"
            className="form-icon-input"
            placeholder="Password"
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semi-bold"
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <Button
        variant="primary"
        className="w-100 mb-3"
        onClick={handleLogin}
        disabled={loading}
      >
        Sign In
      </Button>
      {loginError && <Alert variant="soft-warning">{loginError}</Alert>}
      <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Create an account
        </Link>
      </div>
    </>
  );
};

const useFormInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  };
};

function validateEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
export default SignInForm;
