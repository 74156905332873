import Button from 'components/base/Button';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Card, Row } from 'react-bootstrap';
import classNames from 'classnames';

const AddDataSources = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    const url =
      'https://www.facebook.com/v19.0/dialog/oauth?client_id=405963573152364&redirect_uri=https://api.infora.agency/api/v1/data-source/auth/facebook/callback&scope=public_profile,email,ads_management,ads_read,business_management';
    window.open(
      url,
      '_blank',
      'width=600,height=400,toolbar=no,menubar=no,scrollbars=yes,resizable=yes'
    );
    navigate('/panel/data-sources');
  };
  return (
    <PhoenixDocCard className="mb-4">
      <PhoenixDocCard.Body>
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Add Data Source</h2>
          </div>
        </div>
        <Row className="g-4">
          <Col sm={6} lg={4}>
            <Card className={'text-bg-light'}>
              <Card.Body>
                <Card.Title
                  as="div"
                  className={classNames({ 'text-black': true })}
                >
                  Facebook Ads
                </Card.Title>
                <Card.Text>Some quick example text to build.</Card.Text>
                <Button variant="secondary" size="lg" onClick={handleClick}>
                  Add
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4}>
            <Card className={'text-bg-light'}>
              <Card.Body>
                <Card.Title
                  as="div"
                  className={classNames({ 'text-black': true })}
                >
                  Microsoft
                </Card.Title>
                <Card.Text>Some quick example text to build.</Card.Text>
                <Button variant="secondary" size="lg">
                  Add
                </Button>{' '}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} lg={4}>
            <Card className={'text-bg-light'}>
              <Card.Body>
                <Card.Title
                  as="div"
                  className={classNames({ 'text-black': true })}
                >
                  Google Ads
                </Card.Title>
                <Card.Text>Some quick example text to build.</Card.Text>
                <Button variant="secondary" size="lg">
                  Add
                </Button>{' '}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </PhoenixDocCard.Body>
    </PhoenixDocCard>
  );
};
export default AddDataSources;
